import { useState, useRef, useEffect } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import { useAlertActions } from "../../../../redux/actions";
import { useLoseFocus } from "../../../utilities/useLoseFocus";

import {
  updateCustomerCard,
  deleteCustomerCards,
  getPaymentMethods,
} from "../../../../apis/creditCard";
import { addDeposit } from "../../../../apis/deposit";
import { MENU_MAP } from "../../common";
import { isAccessTokenExpired } from "../../../../functionUtilities/checkAccessTokenExpiry";

// dummy data
const CREDIT_CARD_DATA = [];

export const formatExpiryDate = (value) => {
  return `20${value.substring(2, 4)}/${value.substring(0, 2)}`;
};
export const formatCreditCard = (value) => {
  return `xxxx-xxxx-xxxx-${value.substring(value.length - 4, value.length)}`;
};

export default function useCardFunctions() {
  const { openAlertBar } = useAlertActions();
  const history = useHistory();

  const [creditCardIsLoading, setCreditCardIsLoading] = useState(false);
  const [creditCardData, setCreditCardData] = useState(CREDIT_CARD_DATA);
  const [creditCardDataSelectedRowIds, setCreditCardDataSelectedRowIds] =
    useState({});
  const [creditCardDataLatestUpdate, setCreditCardDataLatestUpdate] = useState(
    new Date().toString()
  );

  const [
    numberOfFundsDepositedSinceAppStart,
    setNumberOfFundsDepositedSinceAppStart,
  ] = useState(0);

  const [fundsAmount, setFundsAmount] = useState("");
  const [fundsCard, setFundsCard] = useState("");

  const [addCardDialogVisible, setAddCardDialogVisible] = useState(false);
  const [deleteCardDialogVisible, setDeleteCardDialogVisible] = useState(false);
  const [addFundsDialogVisible, setAddFundsDialogVisible] = useState(false); // remove

  const [
    cardAsDefaultPopoverAnchorPosition,
    setCardAsDefaultPopoverAnchorPosition,
  ] = useState(null);

  const [deleteCardIsLoading, setDeleteCardIsLoading] = useState(false);
  const [issueChallenge, setIssueChallenge] = useState(false);
  const [formStringFromBackend, setFormStringFromBackend] = useState("");

  const creditCardDefaultIdRef = useRef(null);
  const creditCardClickedIdRef = useRef(null);
  const toDeleteCardIdRef = useRef(null);
  const setAsDefaultPopoverRef = useRef(null);
  const mountedRef = useRef(false);

  const refreshCreditCardTableData = (
    data,
    defaultId,
    clickedId,
    forceUpdate = true
  ) => {
    let newData = [...data];

    newData = newData.map((e, i) => {
      if (defaultId !== null && i.toString() === defaultId.toString()) {
        return {
          ...e,
          rowStyle: { background: "#F1F2F3" },
        };
      } else if (
        clickedId !== null &&
        clickedId &&
        i.toString() === clickedId.toString()
      ) {
        return {
          ...e,
          rowStyle: { background: "#E6F7FF" },
        };
      }

      return {
        ...e,
        rowStyle: { background: "unset" },
      };
    });

    setCreditCardData(newData);

    if (!forceUpdate) {
      return;
    }

    setCreditCardDataLatestUpdate(new Date().toString());
  };

  const fetchCreditCardData = async () => {
    try {
      setCreditCardIsLoading(true);

      // const result = await getCustomerCards();
      const { data } = await getPaymentMethods();

      if (!mountedRef.current) {
        return;
      }

      let creditCards = [];
      let newDefaultCardId = null;

      if (data.data) {
        creditCards = data.data.map((e, i) => {
          if (e.isDefault) {
            newDefaultCardId = i;
          }

          return {
            ...e,
            expiryDate: e.expiryDate,
          };
        });

        creditCardDefaultIdRef.current = newDefaultCardId;
      }

      refreshCreditCardTableData(
        creditCards,
        newDefaultCardId,
        creditCardClickedIdRef.current
      );
    } catch (err) {
      // do something with error
    }

    setCreditCardIsLoading(false);
  };

  const deleteCards = async (cardIds) => {
    try {
      setDeleteCardIsLoading(true);

      const result = await deleteCustomerCards(cardIds);

      if (!mountedRef.current) {
        return;
      }

      setDeleteCardIsLoading(false);

      if (!result.data.status) {
        const successes = result.data.data.filter((e) => e.status);

        const errors = result.data.data
          .filter((e) => !e.status)
          .map((e) => e.operation);

        if (errors.length) {
          openAlertBar(errors.join("\n"), false);
        }

        if (successes.length) {
          return true;
        }

        return null;
      }

      return result.data;
    } catch (err) {
      const errorMessage = err?.response?.data?.title;

      setDeleteCardIsLoading(false);
      openAlertBar(errorMessage ?? "Something happened", false);

      return null;
    }
  };

  const addFunds = async (cardId, amount, purchaseBody) => {
    try {
      const result = await addDeposit(cardId, amount, purchaseBody);
      if (
        result.data.code &&
        result.data.code.toLowerCase() === "C".toLowerCase()
      ) {
        setFormStringFromBackend(result?.data?.data);
        setIssueChallenge(true);
      }
      if (!mountedRef.current) {
        return null;
      }

      return result.data;
    } catch (err) {
      const errorMessage = err?.response?.data?.title;

      openAlertBar(errorMessage ?? "Something happened", false);

      return null;
    }
  };

  const setCardAsDefault = async (id) => {
    try {
      setCreditCardIsLoading(true);

      const card = {
        id,
        isDefault: true,
      };

      const result = await updateCustomerCard(card);

      if (!mountedRef.current) {
        return;
      }

      setCreditCardIsLoading(false);

      if (!result.data.status) {
        openAlertBar(result.data.message, false);

        return null;
      }

      return result.data;
    } catch (err) {
      const errorMessage = err?.response?.data?.title;

      setDeleteCardIsLoading(false);
      openAlertBar(errorMessage ?? "Something happened", false);

      return null;
    }
  };

  useEffect(() => {
    const init = async () => {
      await fetchCreditCardData();
      // getUserSubscription(gettingUserSubscription());
      const qs = queryString.parse(history.location.search);

      if (!qs.status) {
        return;
      }

      if (qs.status === "succeeded") {
        openAlertBar("Your deposit has been successfully submitted", true);
      } else {
        if (qs.status === "success") {
          return;
        }
        openAlertBar("There was an error when submitting your deposit", false);
      }

      history.push(MENU_MAP.SETTINGS_ACCOUNT_PAYMENTS.route);
    };

    mountedRef.current = true;

    if (!isAccessTokenExpired()) {
      init();
    }

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const transactionDropdownListFormatter = (i) => {
  //   return (
  //     <div style={{ display: 'flex' }}>
  //       {TRANSACTION_DROPDOWN_LIST[i]}
  //       {
  //         TRANSACTION_DROPDOWN_LIST[i] === selectedTransactionDropdown && (
  //           <>
  //             &nbsp;
  //             <Chip size="small" label="Default" color="primary" style={{ cursor: 'pointer' }} />
  //           </>
  //         )
  //       }
  //     </div>
  //   );
  // };

  const onAddCardDialogSubmit = async () => {
    creditCardClickedIdRef.current = null;
    setCreditCardDataSelectedRowIds({});
    setAddCardDialogVisible(false);
    await fetchCreditCardData();
  };

  const onDeleteCard = async (id, countryCode) => {
    const result = await deleteCards([{ id, countryCode }]);

    if (!result) {
      return;
    }

    toDeleteCardIdRef.current = null;
    creditCardClickedIdRef.current = null;
    setCreditCardDataSelectedRowIds({});
    setDeleteCardDialogVisible(false);
    await fetchCreditCardData();
  };

  const onDeleteAllCards = async () => {
    const result = await deleteCards(
      creditCardData
        .filter((e, i) =>
          Object.keys(creditCardDataSelectedRowIds).includes(i.toString())
        )
        .map((e) => ({
          id: e.paymentMethodId,
          countryCode: e.billingAddress.countryCode,
        }))
    );

    if (!result) {
      return;
    }

    creditCardClickedIdRef.current = null;
    setCreditCardDataSelectedRowIds({});
    setDeleteCardDialogVisible(false);
    await fetchCreditCardData();
  };

  const onCreditCardTableCellClick = (e, cell) => {
    if (
      cell.column.id !== "cardHolderName" &&
      cell.column.id !== "cardNumber" &&
      cell.column.id !== "expiryDate"
    ) {
      return;
    }

    const id = cell.row.id;

    refreshCreditCardTableData(
      creditCardData,
      creditCardDefaultIdRef.current,
      id,
      false
    );

    creditCardClickedIdRef.current = id;
    setCardAsDefaultPopoverAnchorPosition({ x: e.pageX, y: e.pageY });
  };

  const onSelectCardAsDefault = async () => {
    const id = creditCardClickedIdRef.current;

    creditCardClickedIdRef.current = null;
    setCardAsDefaultPopoverAnchorPosition(null);

    const result = await setCardAsDefault(creditCardData[id].id);

    if (!result) {
      return;
    }

    refreshCreditCardTableData(creditCardData, id, id, false);
    creditCardDefaultIdRef.current = id;
  };

  useLoseFocus(setAsDefaultPopoverRef, () => {
    creditCardClickedIdRef.current = null;
    setCardAsDefaultPopoverAnchorPosition(null);
    refreshCreditCardTableData(
      creditCardData,
      creditCardDefaultIdRef.current,
      null,
      false
    );
  });

  return {
    refreshCreditCardTableData,
    fetchCreditCardData,
    deleteCards,
    addFunds,
    setCardAsDefault,
    onAddCardDialogSubmit,
    onDeleteCard,
    onDeleteAllCards,
    onCreditCardTableCellClick,
    onSelectCardAsDefault,
    toDeleteCardIdRef,
    setAsDefaultPopoverRef,
    creditCardIsLoading,
    creditCardData,
    creditCardDataLatestUpdate,
    setCreditCardDataLatestUpdate,
    creditCardDataSelectedRowIds,
    setCreditCardDataSelectedRowIds,
    deleteCardDialogVisible,
    setDeleteCardDialogVisible,
    cardAsDefaultPopoverAnchorPosition,
    setCardAsDefaultPopoverAnchorPosition,
    setAddCardDialogVisible,
    addCardDialogVisible,
    deleteCardIsLoading,
    formStringFromBackend,
    issueChallenge,
    setIssueChallenge,
    fundsAmount,
    setFundsAmount,
    fundsCard,
    setFundsCard,
    addFundsDialogVisible,
    setAddFundsDialogVisible,
    numberOfFundsDepositedSinceAppStart,
    setNumberOfFundsDepositedSinceAppStart,
  };
}
