import { useState, useEffect, useRef, lazy, Suspense } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Switch,
  Route,
  useHistory,
  matchPath,
  Redirect,
} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

//local imports
import "../../styles/scss/masterPage/masterPage.scss";

import useCardFunctions from "./settings/account/useCardFunctions";
import * as tokenActions from "../../redux/actions/tokenActions";
import { useLoseFocus } from "../utilities/useLoseFocus";
import { MasterPageContext } from "./masterPageContext";
import { MENU_MAP } from "./common";
import {
  useAlertActions,
  useUserActions,
  useNotificationActions,
} from "../../redux/actions";
import { isAccessTokenExpired } from "../../functionUtilities/checkAccessTokenExpiry";
import {
  accessTokenSessionExpiryKey,
  accessTokenSessionKey,
  getLandingPageURL,
  refreshTokenKey,
  tokenCookieName,
} from "../../environment/env_dev";
import {
  gettingUserSubscription,
  gettingAllSubscriptionPlans,
} from "../../redux/thunk/userSubscriptionThunk";
import { loadReferralCode } from "../../redux/actions/referralCodeActions";
import { settingReferralCode } from "../../redux/thunk/referralCodeThunk";
import { saveLoginSelectedTab } from "../../redux/actions/loginSelectedTabActions";
import { manageUserSubscription } from "../../apis/subscriptionService";
import { CancelPlanDialog } from "./settings/account/subscription/Subscription";
import { setOpenLoginDialog } from "../../redux/thunk/openLoginDialogThunk";
import { clearTokens } from "../../functionUtilities/getTokenAndExpireTime";

import LabelBatchDialog from "../utilities/LabelBatchDialog";
import ScrollToTop from "./ScrollToTop";
// import ErrorBoundary from '../utilities/ErrorBoundary';
import useSignalRConnection from "../../hooks/useSignalRConnection";
import { RootState } from "../../redux/reducers";

import { useAllowStickyPosition } from "../utilities/useAllowStickyPosition";

import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";

import ReferralBonusClaimDialog from "./referralProgram/ReferralBonusClaimDialog";
import PrivateRoute from "../utilities/PrivateRoute";
import MasterPageHeader from "./MasterPageHeader";
// import useDataDogRUM from "../../hooks/useDataDogRUM";
import FreightIntroDialog from "../freight-intro/FreightIntoDialog";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Orders = lazy(() => import("./orders/Orders"));
const Shipments = lazy(() => import("./shipments/Shipments"));
const Settings = lazy(() => import("./settings/Settings"));
const Warehousing = lazy(() => import("./warehousing/Warehousing"));
const AlertBar = lazy(() => import("../utilities/AlertBar"));
const UpdateCreditCardDialog = lazy(
  () => import("./settings/account/subscription/UpdateCreditCardDialog")
);
const NotFound = lazy(() => import("../utilities/NotFound"));
const AddFundsDialog = lazy(() => import("../add-funds-dialog/AddFundsDialog"));
const AddCardDialog = lazy(() => import("../add-card-dialog/AddCardDialog"));
const InitShopify = lazy(
  () => import("./settings/integrations/StoreSetup/InitShopify")
);
const Analytic = lazy(() => import("./Analytic/Analytic"));
const Warnings = lazy(() => import("../utilities/Warnings/Warnings"));
const SEOHead = lazy(() => import("../seo/SEOHead"));
const CustomerSupport = lazy(() => import("./customerSupport/CustomerSupport"));
const Onboard = lazy(() => import("./onboard/Onboard"));
const ReferralProgram = lazy(() => import("./referralProgram/ReferralProgram"));

const spinnerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "80vh",
  backgroundColor: "#fff",
  color: "#707070",
};

const stickyPositionRoutes = [
  MENU_MAP.TERMS_OF_USE.route,
  MENU_MAP.PRIVACY_POLICY.route,
  MENU_MAP.PARTNER_TYPE_FULFILLMENT.route,
  MENU_MAP.PARTNER_TYPE_TECHNOLOGY.route,
  MENU_MAP.SETTINGS_COMMISSION_PROGRAM_AGREEMENT.route,
  MENU_MAP.SETTINGS_CREDIT_CARD_CONSENT.route,
  MENU_MAP.UPDATE_NOTES.route,
];

// returns whether route is a considered login page and exempt from having to be logged in to visit
// used for check for route guarding and for displaying master page header
const isLoginAndExemptedRoute = (route: string) => {
  if (route.startsWith(MENU_MAP.SHOPIFY_OAUTH.route)) {
    // covers shopify auth route
    return true;
  } else if (route.startsWith(MENU_MAP.HELP_CENTER.route)) {
    // covers all legacy help pages
    return true;
  }
  return false;
};

const MasterPage = ({
  addAccessTokenWatcher,
  startGettingUserSubscription,
  startGettingAllSubscriptionPlans,
  loadingReferralCode,
  settingReferralCode,
}) => {
  const history = useHistory();
  const alertRef = useRef(null);
  // uses useLocation, so needs to be inside browser router component
  // also used to track each individual page path views
  const { sendCustomEvent } = useGoogleAnalytics();
  // useDataDogRUM();
  const { alert, user } = useSelector((state: RootState) => state);
  useLoseFocus(alertRef, () => closeAlertBar());
  useSignalRConnection(user.user.account_no);

  const { closeAlertBar, openAlertBar } = useAlertActions();
  const { fetchUser, fetchUserReferralData } = useUserActions();
  const { fetchNotifications } = useNotificationActions();
  const { allowStickyOnInitial } = useAllowStickyPosition(stickyPositionRoutes);

  const [bannerCollapsed] = useState(false);
  const [collapsing] = useState(false);
  const [selectedHeaderMenuKey, setSelectedHeaderMenuKey] = useState(
    MENU_MAP.DASHBOARD.key
  );

  const [selectedOrdersMenuKey, setSelectedOrdersMenuKey] = useState(
    MENU_MAP.ORDERS.key
  );
  const [selectedOrdersRoute, setSelectedOrdersRoute] = useState(
    MENU_MAP.ORDERS.route
  );
  const [selectedShipmentsMenuKey, setSelectedShipmentsMenuKey] = useState(
    MENU_MAP.SHIPMENTS.key
  );
  const [selectedShipmentsRoute, setSelectedShipmentsRoute] = useState(
    MENU_MAP.SHIPMENTS.route
  );
  const [selectedSettingsMenuKey, setSelectedSettingsMenuKey] = useState(
    MENU_MAP.SETTINGS.key
  );
  const [selectedSettingsRoute, setSelectedSettingsRoute] = useState(
    MENU_MAP.SETTINGS.route
  );
  const [selectedWarehousingMenuKey, setSelectedWarehousingMenuKey] = useState(
    MENU_MAP.WAREHOUSING.key
  );
  const [selectedWarehousingRoute, setSelectedWarehousingRoute] = useState(
    MENU_MAP.WAREHOUSING.route
  );
  const [selectedCustomerSupportMenuKey, setSelectedCustomerSupportMenuKey] =
    useState(MENU_MAP.CUSTOMER_SUPPORT.key);
  const [selectedCustomerSupportRoute, setSelectedCustomerSupportRoute] =
    useState(MENU_MAP.CUSTOMER_SUPPORT.route);

  const [selectedAnalyticMenuKey, setSelectedAnalyticMenuKey] = useState(
    MENU_MAP.ANALYTIC.key
  );
  const [selectedAnalyticRoute] = useState(MENU_MAP.ANALYTIC.route);
  const [settingsPageKey, setSettingsPageKey] = useState(new Date().toString());
  const [shipmentsPageKey, setShipmentsPageKey] = useState(
    new Date().toString()
  );

  const [documentWidth, setDocumentWidth] = useState(0);
  const [openUpdateCreditCardDialog, setOpenUpdateCreditCardDialog] =
    useState(false);
  const [cancelPlanDialogOpen, setCancelPlanDialogOpen] = useState(false);

  // will be changed to true when the user hasn't claimed their $5 referral bonus and trigger a dialog
  const [unClaimedReferralBonus, setUnClaimedReferralBonus] = useState(false);

  const handleResize = () => {
    setDocumentWidth(window.innerWidth);
  };

  useEffect(() => {
    const init = async () => {
      const route = window.location.pathname;

      await fetchUserReferralData();
      const { isSubscribed, openBonusDialog } = await fetchUser();

      fetchNotifications();
      addAccessTokenWatcher();

      if (!isSubscribed) {
        setOpenUpdateCreditCardDialog(true);
      }

      // open bonus claim dialog
      if (openBonusDialog) {
        setUnClaimedReferralBonus(true);
      }

      resolveHeaderMenuKey(route);
    };

    // check if token is expired then call get user subscription
    if (!isAccessTokenExpired()) {
      startGettingUserSubscription();
    }
    //call get all subscription plans
    startGettingAllSubscriptionPlans();

    // call init function from above
    init();

    //add event listener for window resize
    setTimeout(() => {
      handleResize();
    });

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resolveSelectedMenuKey = (route) => {
    const obj = Object.values(MENU_MAP).find((e) => e.route === route);

    if (!obj) {
      history.push(MENU_MAP.DASHBOARD.route);
      resolveHeaderMenuKey(MENU_MAP.DASHBOARD.route);

      return;
    }

    if (route.startsWith(MENU_MAP.ORDERS.route)) {
      if (obj.key === MENU_MAP.ORDERS.key) {
        setSelectedOrdersMenuKey(MENU_MAP.ORDERS_AWAITING_PAYMENT.key);
      } else {
        setSelectedOrdersMenuKey(obj.key);
      }

      setSelectedOrdersRoute(route);

      return;
    }

    if (route.startsWith(MENU_MAP.SHIPMENTS.route)) {
      if (obj.key === MENU_MAP.SHIPMENTS.key) {
        // setSelectedShipmentsMenuKey(MENU_MAP.SHIPMENTS_QUICK_QUOTE.key);
        setSelectedShipmentsMenuKey(MENU_MAP.SHIPMENTS_CREATE_SHIPMENT.key);
      } else {
        setSelectedShipmentsMenuKey(obj.key);
      }

      setSelectedShipmentsRoute(route);

      return;
    }

    if (route.startsWith(MENU_MAP.SETTINGS.route)) {
      if (obj.key === MENU_MAP.SETTINGS.key) {
        setSelectedSettingsMenuKey(MENU_MAP.SETTINGS_ACCOUNT_MY_PROFILE.key);
      } else {
        setSelectedSettingsMenuKey(obj.key);
      }

      setSelectedSettingsRoute(route);

      return;
    }

    if (route.startsWith(MENU_MAP.ANALYTIC.route)) {
      setSelectedAnalyticMenuKey(MENU_MAP.ANALYTIC_OVERVIEW.key);
    } else {
      setSelectedAnalyticMenuKey(obj.key);
    }

    if (route.startsWith(MENU_MAP.WAREHOUSING.route)) {
      if (obj.key === MENU_MAP.WAREHOUSING.key) {
        setSelectedWarehousingMenuKey(MENU_MAP.WAREHOUSING_MY_PRODUCTS.key);
      } else {
        setSelectedWarehousingMenuKey(obj.key);
      }

      setSelectedWarehousingRoute(route);

      return;
    }

    if (route.startsWith(MENU_MAP.CUSTOMER_SUPPORT.route)) {
      if (obj.key === MENU_MAP.CUSTOMER_SUPPORT.key) {
        setSelectedCustomerSupportMenuKey(
          MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS.key
        );
      } else {
        setSelectedCustomerSupportMenuKey(obj.key);
      }

      setSelectedCustomerSupportRoute(route);
    }
  };

  const resolveHeaderMenuKey = (route) => {
    if (route === MENU_MAP.DASHBOARD.route) {
      setSelectedHeaderMenuKey(MENU_MAP.DASHBOARD.key);

      return;
    }

    if (route === MENU_MAP.ONBOARD.route) {
      setSelectedHeaderMenuKey(MENU_MAP.ONBOARD.key);

      return;
    }

    if (route.startsWith(MENU_MAP.ORDERS.route)) {
      setSelectedHeaderMenuKey(MENU_MAP.ORDERS.key);

      return;
    }

    if (route.startsWith(MENU_MAP.SHIPMENTS.route)) {
      setSelectedHeaderMenuKey(MENU_MAP.SHIPMENTS.key);

      return;
    }

    if (route.startsWith(MENU_MAP.REFERRAL_PROGRAM.route)) {
      setSelectedHeaderMenuKey(MENU_MAP.REFERRAL_PROGRAM.key);

      return;
    }

    if (route.startsWith(MENU_MAP.SETTINGS.route)) {
      setSelectedHeaderMenuKey(MENU_MAP.SETTINGS.key);

      return;
    }

    if (route.startsWith(MENU_MAP.ANALYTIC.route)) {
      setSelectedHeaderMenuKey(MENU_MAP.ANALYTIC.key);
    }

    if (route.startsWith(MENU_MAP.WAREHOUSING.route)) {
      setSelectedHeaderMenuKey(MENU_MAP.WAREHOUSING.key);

      return;
    }

    if (route.startsWith(MENU_MAP.CUSTOMER_SUPPORT.route)) {
      setSelectedHeaderMenuKey(MENU_MAP.CUSTOMER_SUPPORT.key);
    }
  };

  const cardFunctions = useCardFunctions();
  const {
    setAddFundsDialogVisible,
    setFundsAmount,
    setFundsCard,
    addCardDialogVisible,
    creditCardData,
    onAddCardDialogSubmit,
    setAddCardDialogVisible,
    fetchCreditCardData,
  } = cardFunctions;

  const masterPageContext = {
    user,
    resolveSelectedMenuKey,
    resolveHeaderMenuKey,
    setSettingsPageKey,
    setShipmentsPageKey,
    loadingReferralCode,
    settingReferralCode,
    cardFunctions,
    sendCustomEvent,
    setSelectedSettingsMenuKey,
    setSelectedHeaderMenuKey,
    setAddFundsDialogVisible,
    setUnClaimedReferralBonus,
  };

  const onHeaderMenuChange = (key, route) => {
    setSelectedHeaderMenuKey(key);

    let newRoute = route;

    switch (newRoute) {
      case MENU_MAP.ORDERS.route:
        newRoute = selectedOrdersRoute;
        break;
      case MENU_MAP.SHIPMENTS.route:
        newRoute = selectedShipmentsRoute;
        break;
      case MENU_MAP.SETTINGS.route:
        newRoute = selectedSettingsRoute;
        break;
      case MENU_MAP.WAREHOUSING.route:
        newRoute = selectedWarehousingRoute;
        break;
      case MENU_MAP.CUSTOMER_SUPPORT.route:
        newRoute = selectedCustomerSupportRoute;
        break;
      case MENU_MAP.ANALYTIC.route:
        newRoute = selectedAnalyticRoute;
        break;
      default:
        break;
    }

    history.push(newRoute);
  };

  const onLogoClick = () => {
    setSelectedHeaderMenuKey(MENU_MAP.DASHBOARD.key);
    history.push(MENU_MAP.DASHBOARD.route);
  };

  const onProfileMenuClick = () => {
    setSelectedSettingsMenuKey(MENU_MAP.SETTINGS_ACCOUNT_MY_PROFILE.key);
    setSelectedHeaderMenuKey(MENU_MAP.SETTINGS.key);
    history.push(MENU_MAP.SETTINGS_ACCOUNT_MY_PROFILE.route);
    setSettingsPageKey(new Date().toString());
  };

  const onBalanceClick = () => {
    // setSelectedSettingsMenuKey(MENU_MAP.SETTINGS_ACCOUNT_PAYMENTS.key);
    // setSelectedHeaderMenuKey(MENU_MAP.SETTINGS.key);
    // history.push(MENU_MAP.SETTINGS_ACCOUNT_PAYMENTS.route);
    // setSettingsPageKey((new Date()).toString());

    setFundsAmount("");
    setFundsCard("");
    setAddFundsDialogVisible(true);
  };

  const onLogoutClick = () => {
    document.cookie = `${tokenCookieName}=`;
    window.localStorage.setItem("accesstoken", "");
    window.localStorage.setItem("expireAt", "");

    clearTokens(
      tokenCookieName,
      accessTokenSessionKey,
      accessTokenSessionExpiryKey,
      refreshTokenKey
    );

    // if (DEFAULT_URL.includes("app.shipvista.com"))
    //   window.location.href = 'https://www.shipvista.com';
    // else
    window.location.href = getLandingPageURL() + "/?e=logout";
  };

  const isMobileView = () => {
    return documentWidth <= 720;
  };

  const hideMasterHeader = () => {
    if (window.location.pathname === MENU_MAP.SHOPIFY_OAUTH.route) return true;
    return false;
  };

  const isLoginPage = () => {
    const currentRoute = window.location.pathname;
    const matchedUrl = Object.entries(MENU_MAP).find(
      ([, e]) =>
        !e.isProtected &&
        Boolean(matchPath(currentRoute, { path: e.route, exact: true }))
    );
    return isLoginAndExemptedRoute(currentRoute) || Boolean(matchedUrl);
  };

  // if (!tokenReady) {
  //   return <div></div>;
  // }

  return (
    <Suspense
      fallback={
        <div style={spinnerStyles}>
          <CircularProgress color="inherit" />
        </div>
      }
    >
      <MasterPageContext.Provider value={masterPageContext}>
        <div
          className={`master-page ${isMobileView() ? " mobile" : ""} ${
            allowStickyOnInitial ? "no-overflow" : ""
          } ${
            !isLoginPage() && (collapsing || !bannerCollapsed) ? "expanded" : ""
          } ${!isLoginPage() ? "logged-in" : ""}`}
        >
          {!hideMasterHeader() && !isLoginPage() && (
            <>
              <MasterPageHeader
                userProfileName={user.user.user_name}
                selectedKey={selectedHeaderMenuKey}
                onChange={onHeaderMenuChange}
                onLogoClick={onLogoClick}
                onProfileMenuClick={onProfileMenuClick}
                onBalanceClick={onBalanceClick}
                onLogoutClick={onLogoutClick}
                isMobileView={isMobileView()}
              />
            </>
          )}
          <ScrollToTop />
          <SEOHead />
          {/* <ErrorBoundary> */}
          <Suspense
            fallback={
              <div style={spinnerStyles}>
                <CircularProgress color="inherit" />
              </div>
            }
          >
            <Switch>
              <Route exact path={MENU_MAP.LANDING_PAGE.route}>
                <Redirect to={MENU_MAP.DASHBOARD.route} />
              </Route>
              <PrivateRoute
                path={MENU_MAP.DASHBOARD.route}
                component={Dashboard}
              />
              <PrivateRoute path={MENU_MAP.ONBOARD.route} component={Onboard} />
              <PrivateRoute
                path={MENU_MAP.ORDERS.route}
                component={Orders}
                componentProps={{
                  selectedMenuKey: selectedOrdersMenuKey,
                }}
              />
              <PrivateRoute
                path={MENU_MAP.SHIPMENTS.route}
                component={Shipments}
                componentProps={{
                  key: shipmentsPageKey,
                  selectedMenuKey: selectedShipmentsMenuKey,
                }}
              />
              <PrivateRoute
                path={MENU_MAP.REFERRAL_PROGRAM.route}
                component={ReferralProgram}
                componentProps={{ referralCode: user?.user?.referralCode }}
              />
              <PrivateRoute
                path={MENU_MAP.SETTINGS.route}
                component={Settings}
                componentProps={{
                  key: settingsPageKey,
                  selectedMenuKey: selectedSettingsMenuKey,
                }}
              />
              <PrivateRoute
                path={MENU_MAP.WAREHOUSING.route}
                component={Warehousing}
                componentProps={{ selectedMenuKey: selectedWarehousingMenuKey }}
              />
              <PrivateRoute
                path={MENU_MAP.ANALYTIC.route}
                component={Analytic}
                componentProps={{ selectedMenuKey: selectedAnalyticMenuKey }}
              />
              <PrivateRoute
                path={MENU_MAP.CUSTOMER_SUPPORT.route}
                component={CustomerSupport}
                componentProps={{
                  selectedMenuKey: selectedCustomerSupportMenuKey,
                }}
              />
              <Route
                path={MENU_MAP.SHOPIFY_OAUTH.route}
                component={InitShopify}
              />
              <Route render={() => <NotFound />} />
            </Switch>
          </Suspense>
          {/* </ErrorBoundary> */}
          {user.user.account_no && <Warnings />}
          {alert.isAlertBarOpen && <AlertBar />}
          {openUpdateCreditCardDialog && (
            <UpdateCreditCardDialog
              open={openUpdateCreditCardDialog}
              setOpen={setOpenUpdateCreditCardDialog}
              onSubmit={(e) => {
                let data = {
                  billingDetails: {
                    paymentMethodId: e.cardDetails.paymentMethodId,
                  },
                  ClientCallBack: window.location.href,
                  subscriptionplanid:
                    e.userSubscriptionDetails?.data.subscriptionPlan
                      ?.subscriptionplanid,
                };

                manageUserSubscription(data).then((res) => {
                  if (res.data.status) {
                    openAlertBar(
                      "You have successfully updated your billing information",
                      true
                    );
                    setOpenUpdateCreditCardDialog(false);
                    startGettingUserSubscription();
                  } else {
                    openAlertBar(
                      "There was an error processing your request to update billing information",
                      false
                    );
                    setOpenUpdateCreditCardDialog(false);
                  }
                });
              }}
              onDowngrade={() => {
                setCancelPlanDialogOpen(true);
                setOpenUpdateCreditCardDialog(false);
              }}
            />
          )}
          {cancelPlanDialogOpen && (
            <CancelPlanDialog
              open={cancelPlanDialogOpen}
              setOpen={setCancelPlanDialogOpen}
              onSubmit={() => {
                setCancelPlanDialogOpen(false);
                startGettingUserSubscription();
              }}
              // billingInformationDetails={null}
            />
          )}
          {unClaimedReferralBonus && (
            <ReferralBonusClaimDialog
              open={unClaimedReferralBonus}
              setOpen={setUnClaimedReferralBonus}
            />
          )}
          {addCardDialogVisible && (
            <AddCardDialog
              isOpen={addCardDialogVisible}
              initialMakeDefault={creditCardData.length === 0}
              setIsOpen={(value) => setAddCardDialogVisible(value)}
              setAddFundsDialogVisible={setAddFundsDialogVisible}
              onSubmit={onAddCardDialogSubmit}
              fetchCreditCardData={fetchCreditCardData}
            />
          )}

          <FreightIntroDialog />
          <AddFundsDialog />
          <LabelBatchDialog />
        </div>
      </MasterPageContext.Provider>
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    user: state.user,
    userSubscription: state.userSubscription,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAccessTokenWatcher: bindActionCreators(
      tokenActions.addAccessTokenWatcher,
      dispatch
    ),
    startGettingUserSubscription: () => dispatch(gettingUserSubscription()),
    loadingReferralCode: () => dispatch(loadReferralCode()),
    settingReferralCode: (_referralCode: string) =>
      dispatch(settingReferralCode(_referralCode)),
    setSelectedTab: (_tabNumber: number) =>
      dispatch(saveLoginSelectedTab(_tabNumber)),
    setLoginDialogOpen: (_open: boolean) => dispatch(setOpenLoginDialog(_open)),
    startGettingAllSubscriptionPlans: () =>
      dispatch(gettingAllSubscriptionPlans()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterPage);
