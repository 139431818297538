import React, { useEffect, useState } from 'react';
import { Address } from '../../@type/Orders/Address';
import { addressMapper } from '../../mappers/addressBook/addressMapper';

/**
 * A custom hook that is used for adding google address search to a input or textarea element with a given id(that should not change)
 * @param {T} value A state variable that stores properties of the changing address
 * @param {React.Dispatch<React.SetStateAction<T>>} setValue A react state setter function to update the value parameter
 * @param {string} id The id of the HTML input element to attach the GoogleAddressSearch event listener
 * @param {T} defaultValue The initial/default value of the value parameter 
 * @param {boolean} initialize Whether or not to initialize the value to the defaultValue. Optional, but default is true.
*/
export const useGoogleAddressSearch =
    <T extends Pick<Address, 'streetAddress' | 'streetAddress2' | 'state' | 'stateCode' | 'city' | 'postalCode' | 'countryCode' | 'country' | 'formattedAddress'>>
        (value: T, setValue: React.Dispatch<React.SetStateAction<T>>, id: string, defaultValue: T, initialize: boolean = true) => {

        const [formattedAddress, setFormattedAddress] = useState(addressMapper(value));

        const [submits, setSubmits] = useState(0);

        useEffect(() => {
            let observer: any = null;

            const attachEventToElement = async () => {
                let element: any = document.getElementById(id);

                if (!element) {
                    element = await waitForElm(id, (obs) => observer = obs);
                }

                const autocomplete = new (window as any).google.maps.places.Autocomplete(element, {
                    types: ['address']
                })
                autocomplete.inputId = id;

                autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace();

                    let formattedAddress = place.formatted_address;
                    let postalCode = "";
                    let country = "";
                    let countryCode = "";
                    let streetNumber = "";
                    let streetName = "";
                    let city = "";
                    let stateRegion = "";
                    let stateCode = "";
                    for (var i = 0; i < place.address_components.length; i++) {
                        for (var j = 0; j < place.address_components[i].types.length; j++) {
                            if (place.address_components[i].types[j] === "postal_code") {

                                postalCode = place.address_components[i].long_name;
                            }
                            if (place.address_components[i].types[j] === 'country') {

                                country = place.address_components[i].long_name;
                                countryCode = place.address_components[i].short_name;
                            }
                            if (place.address_components[i].types[j] === 'street_number') {
                                streetNumber = place.address_components[i].long_name;
                            }
                            if (place.address_components[i].types[j] === 'route') {
                                streetName = place.address_components[i].long_name;
                            }
                            if (place.address_components[i].types[j] === 'locality') {
                                city = place.address_components[i].long_name;
                            }
                            if (place.address_components[i].types[j] === 'administrative_area_level_1') {
                                stateRegion = place.address_components[i].long_name;
                                stateCode = place.address_components[i].short_name;
                            }
                        }
                    }

                    setFormattedAddress({
                        ...formattedAddress,
                        streetAddress: streetNumber + " " + streetName,
                        state: stateRegion,
                        stateCode,
                        city,
                        postalCode,
                        countryCode,
                        country,
                        formattedAddress
                    });
                    // setAddress({ ...address, streetAddress:tempAddress.streetAddress, state:tempAddress.stateRegion,stateCode:tempAddress.stateCode, city:tempAddress.city, postalCode: tempAddress.postalCode, countryCode: tempAddress.countryCode, country: tempAddress.country,  formattedAddress: tempAddress.formattedAddress });

                })
            }

            attachEventToElement();

            return () => {
                if (observer) {
                    observer.disconnect();
                }
            }

        }, [submits]) // eslint-disable-line react-hooks/exhaustive-deps

        useEffect(() => {
            if (initialize) {
                setValue(defaultValue)
            }
        }, [])// eslint-disable-line react-hooks/exhaustive-deps

        useEffect(() => {
            setValue({ ...value, streetAddress: formattedAddress.streetAddress, streetAddress2: "", state: formattedAddress.state, stateCode: formattedAddress.stateCode, city: formattedAddress.city, postalCode: formattedAddress.postalCode, countryCode: formattedAddress.countryCode, country: formattedAddress.country, formattedAddress: formattedAddress.formattedAddress || "" });

        }, [formattedAddress]) // eslint-disable-line react-hooks/exhaustive-deps

        const handleSearch: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
            e.preventDefault();
            setValue({ ...value, formattedAddress: e.target.value });
        }

        // need to call this method when you submit a form so that you can reinitialize the address search
        const handleSubmit = () => {
            setSubmits(curr => curr + 1);
        }

        return { handleSearch, handleSubmit };
    }



function waitForElm(id, callback) {
    return new Promise(resolve => {
        if (document.getElementById(id)) {
            return resolve(document.getElementById(id));
        }

        const observer = new MutationObserver(mutations => {
            if (document.getElementById(id)) {
                resolve(document.getElementById(id));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
        callback(observer);
    });
}
